import axios from 'axios';
import _ from 'lodash'
import moment from 'moment';
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Datepicker from 'components/Datepicker';
import * as CompaniesStore from 'stores/Companies';
import * as ParamsStore from 'stores/Params';
import * as UserStore from 'stores/User';
import * as NetworkStore from 'stores/Network';
import {TextField, Button, TextFieldTags, Dropdown} from '@partssourceinc/react-ui-core';

import 'less/searchcriteria.less';

const SearchFilter = {
    Account: 'Account',
    AssetID: 'AssetID',
    CostCenter: 'CostCenter',
    OrderDateStart: 'StartDate',
    OrderDateEnd: 'EndDate',
    Description: 'Description',
    SerialNumber: 'SerialNumber',
    FacilityId: 'FacilityId',
    InvoiceNumber: 'InvoiceNumber',
    IsDelivered: 'IsDelivered',
    IsBackOrdered: 'IsBackOrdered',
    ItemReceived: 'ItemReceived',
    PartNumber: 'PartNumber',
    StatusId: 'StatusId',
    Manufacturer: 'ManufacturerId',
    Model: 'Model',
    OrderId: 'OrderId',
    PriorityId: 'PriorityId',
    PoNumber: 'PoNumber',
    LineItemId: 'LineItemId',
    RequesterId: 'RequesterId',
    TrackingNumber: 'TrackingNumber',
    WorkOrder: 'WorkOrder',
    ProgramId: 'ProgramId',
    TypeId: 'TypeId',
};

@withRouter
@connect(
    (state) => ({oems: state.companies.oems, user: state.user, network: state.network, params: state.params}),
    _.merge({}, UserStore.actionCreators, CompaniesStore.actionCreators, ParamsStore.actionCreators)
)
export default class SearchCriteria extends React.Component {
    static displayName = 'SearchCriteria';

    static propTypes = {
        onApply: PropTypes.func.isRequired,
        params: ParamsStore.StateShape,
        user: UserStore.StateShape,
        oems: CompaniesStore.OemsShape,
        network: NetworkStore.StateShape,
        ...UserStore.ActionShape,
        ...CompaniesStore.ActionShape,
        ...ParamsStore.ActionShape,
    };

    constructor(props) {
        super(props);
        const {
            params,
            network: {
                initialPage,
                tokenInfo: {subscriptionId},
            },
            user: {settings: {hasPSPlusAccess}},
        } = props;

        let statuses = [];
        let criteria = [];
        let orderTypes = [];
        if (params.wo_id && params.wo && subscriptionId && initialPage === '/orders/history') {
            statuses = [
                {statusId: 5, statusText: 'Delivered'},
                {statusId: 12, statusText: 'Shipped'},
            ];
            criteria = [{
                field: 'select',
                value: '5|12',
            }];
        } else {
            statuses = [
                {statusId: 1, statusText: 'Awaiting Approval'},
                {statusId: 2, statusText: 'Awaiting PO'},
                {statusId: 3, statusText: 'Approval Rejected'},
                // {statusId: 4, statusText: 'Ordered'},
                {statusId: 5, statusText: 'Delivered'},
                {statusId: 6, statusText: 'Ordered'},
                {statusId: 7, statusText: 'Quoted'},
                {statusId: 8, statusText: 'Requested'},
                {statusId: 9, statusText: 'Return Requested'},
                {statusId: 10, statusText: 'Returned'},
                {statusId: 11, statusText: 'Repairing'},
                {statusId: 12, statusText: 'Shipped'},
                {statusId: 13, statusText: 'Submitted For Punchout'},
                {statusId: 14, statusText: 'Initiated'},
                {statusId: 15, statusText: 'Open Service Event'},
                {statusId: 16, statusText: 'Work Completed'},
                {statusId: 17, statusText: 'Info Requested'},
                {statusId: 27, statusText: 'Completed'},
                {statusId: 101, statusText: 'Backordered'},
            ].sort((a, b) => a.statusText.localeCompare(b.statusText))
        }
        orderTypes = [
            {typeId: 1, orderTypeText: 'PART ORDER'},
            {typeId: 4, orderTypeText: 'REPAIR'},
            {typeId: 37, orderTypeText: 'Loaner'},
            {typeId: 17, orderTypeText: 'Service'},
        ]
        this.onFilterTypeChange = this.onFilterTypeChange.bind(this);
        this.addNewFilterRow = this.addNewFilterRow.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
        this.clearParams = this.clearParams.bind(this);

        let types = [
            {name: 'Order Date', type: 'dateRange', field: SearchFilter.OrderDateStart},
            {name: 'Description', type: 'text', field: SearchFilter.Description},
            {name: 'Facility', type: 'tags', field: SearchFilter.FacilityId, values: [], keyField: 'facilityId', valueField: 'facilityName'},
            {name: 'Invoice #', type: 'text', field: SearchFilter.InvoiceNumber},
            {name: 'Item #', type: 'text', field: SearchFilter.PartNumber},
            {name: 'Checked In', type: 'select', field: SearchFilter.ItemReceived, values: [{text: 'Yes', value: 1}, {text: 'No', value: 0}]},
            {name: 'Manufacturer', type: 'select', field: SearchFilter.Manufacturer, values: []},
            {name: 'Order #', type: 'text', field: SearchFilter.OrderId},
            {name: 'PO #', type: 'text', field: SearchFilter.PoNumber},
            {name: 'Priority', type: 'select', field: SearchFilter.PriorityId, values: [{text: 'Normal', value: 2}, {text: 'Expedited', value: 1}]},
            {name: 'Ref #', type: 'text', field: SearchFilter.LineItemId},
            {name: 'Requester', type: 'tags', field: SearchFilter.RequesterId, values: [], keyField: 'contactId', valueField: 'contactName'},
            {name: 'Status', type: 'tags', field: SearchFilter.StatusId, keyField: 'statusId', valueField: 'statusText', values: statuses},
            {name: 'Tracking #', type: 'text', field: SearchFilter.TrackingNumber },
            {name: 'Order Type', type: 'tags', field: SearchFilter.TypeId, keyField: 'typeId', valueField: 'orderTypeText', values: orderTypes},
        ];

        if (hasPSPlusAccess) {
            types.push({name: 'Plus+ Program', type: 'select', field: SearchFilter.ProgramId, values: []});
        }

        this.state = {
            facilityId: 0,
            criteria: criteria,
            wo_id: params.wo_id,
            assetid: params.asset,
            types: types,
            rootReqFields: [],            
            allStatusIds: types.filter(x => x.keyField == 'statusId')[0].values.
                map(x => x.statusId).join('|'),                
        };
    }

    componentDidMount() {
        const {getOems, oems, user: {settings}} = this.props;
        let {rootReqFields} = this.state;
        let fields = _.unionBy(settings.rootRequiredFields,settings.defaultFields,'fieldDefinitionUid');
        if (fields.length > 0) {
            fields.forEach(f => {
                rootReqFields.push({name: f.prompt, type: 'text', field: `${f.fieldDefinitionUid}`}); 
            })
        }
        this.setState({rootReqFields});
        // Ensure that the oems are loaded
        if ((oems || []).length === 0) {
            getOems();
        }

        this.loadSelectionLists(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.loadSelectionLists(newProps);
    }

    async loadSelectionLists(props) {
        const {
            oems,
            user: {facilities, ordersPredefinedFilters},
            params,
            network: {
                initialPage,
                tokenInfo: {subscriptionId, userId},
            },
        } = props;
        let {rootReqFields} = this.state;
        let newState = _.merge({}, _.clone(this.state));

        // Map in the facilities for the current user
        let facilityList = _.find(newState.types, {name: 'Facility'});
        if (facilityList && facilityList.values && facilityList.values.length === 0) {
            facilityList.values = facilities;
        }
        
        let manufacturerList = _.find(newState.types, {name: 'Manufacturer'});
        if (manufacturerList && manufacturerList.values && manufacturerList.values.length === 0) {    
            manufacturerList.values = (oems || []).map(f => {
                return {text: f.name, value: f.id}; 
            });

            this.setState({types: newState.types})
        }
        let restoreFilters = ordersPredefinedFilters && ordersPredefinedFilters.length > 0;
        let programField = _.find(newState.types, {name: 'Plus+ Program'});
        const programIdFilter = ordersPredefinedFilters.find(f => f.field === SearchFilter.ProgramId);

        if (programField && programField.values && programField.values.length === 0) {
            const programData = await axios.get(`/ShoppingService/api/v1/company/programs?userId=${userId}`);

            programField.values = (programData.data || []).map(f => {
                return {text: f.companyName, value: f.programId}; 
            });
            
            const orderDateField = _.clone(_.find(newState.types, {name: 'Order Date'})); 
            if (programIdFilter) {
                newState.criteria.push({
                    fieldInfo: programField,
                    name: programField.name,
                    showSelection: false,
                    value: programIdFilter.value,
                }, {
                    fieldInfo: orderDateField,
                    name: orderDateField.name,
                    showSelection: false,
                    value: {
                        start: ordersPredefinedFilters.find(f => f.field === SearchFilter.OrderDateStart).value,
                        end: ordersPredefinedFilters.find(f => f.field === SearchFilter.OrderDateEnd).value,
                    },
                });
            }
        }

        let requesterList = _.find(newState.types, { name: 'Requester' });
        if (props.params.referer.includes('/repairs') && location.pathname === '/orders/history') {
            restoreFilters = false;
            if (!_.find(newState.criteria, { name: 'Order Type' }) && newState.criteria.length === 0) {
                const repairOrders = _.clone(_.find(newState.types, { name: 'Order Type' }));
                newState.criteria.push({
                    fieldInfo: repairOrders,
                    name: repairOrders.name,
                    showSelection: false,
                    value: { 4: 'Repairs' },
                });
            }

        }
        if (requesterList && requesterList.values && requesterList.values.length === 0) {      
            const requesterData = await axios.get('/ShoppingService/api/v1/company/contacts');
            requesterList.values = requesterData.data.map(c => ({contactName: `${c.firstName} ${c.lastName}`, contactId: c.contactId}));
            requesterList.values = [{contactId: 'All', contactName: 'All'}, ...requesterList.values];

            const requesterField = _.clone(_.find(newState.types, {name: 'Requester'}));
            const orderDateField = _.clone(_.find(newState.types, {name: 'Order Date'}));

            if (programIdFilter) {
                newState.criteria.push({
                    fieldInfo: requesterField,
                    name: requesterField.name,
                    showSelection: false,
                    value: this.setupNewFieldData(requesterField),
                })
            }

            if (!programIdFilter) {
                if (restoreFilters) {
                    newState.criteria = ordersPredefinedFilters;
                } else {
                    newState.criteria.push ({
                        fieldInfo: requesterField,
                        name: requesterField.name,
                        showSelection: false,
                        value: this.setupNewFieldData(requesterField),
                    }, {
                        fieldInfo: orderDateField,
                        name: orderDateField.name,
                        showSelection: false,
                        value: this.setupNewFieldData(orderDateField),
                        });
                }
                if (!!params.wo_id && !!params.wo && subscriptionId && initialPage === '/orders/history') {
                    const statusField = _.clone(_.find(newState.types, {name: 'Status'}));
                    if (!_.find(newState.criteria, {name: 'Status'})) { 
                        newState.criteria.push({
                            fieldInfo: statusField,
                            name: statusField.name,
                            showSelection: false,
                            value: {5: 'Delivered', 12: 'Shipped'},
                        });
                    }
                }
                if (rootReqFields && rootReqFields.length > 0) {                    
                    rootReqFields.forEach(f => {                        
                        newState.types.push({name: f.name, type: f.type, field: f.field}); 
                    });
                }
            }
            
            this.setState(newState, this.applyFilter);
        } else {
            if (restoreFilters) {
                newState.criteria = ordersPredefinedFilters;
            } else {
                this.setState(newState);
            }              
        }
    }

    deleteFilter(filter) {    
        const {criteria} = this.state;        
        let newCriteria = _.clone(criteria);
        _.remove(newCriteria, { name: filter.name });
        if (this.props.params.referer && this.props.params.referer.includes('/repairs') && location.pathname === '/orders/history') {
            this.props.params.referer = null;
        }
        this.setState({criteria: newCriteria});
    }

    updateFilterDateValue(value, filter, name) {
        // Set state directly?  Cool?
        filter.value[name] = value;
    }

    updateFilterValue(value, filter) {
        // Set state directly?  Still cool?
        filter.value = value;
        this.forceUpdate()
    }

    getCriteriaValue(criteria) {
        // return all status id's when no status selected
        let keys = Object.keys(criteria.value);

        if (criteria.fieldInfo.field == 'StatusId' && keys.length == 0) {
            return this.state.allStatusIds;
        }

        if (criteria.fieldInfo.type === 'tags') {            

            if (criteria.fieldInfo.field == 'RequesterId' && criteria.value.All && keys.length == 1) {
                // not removing the 'All' value when there is only one filter set
            } else if (criteria.value.All) {
                delete criteria.value.All;
                if (keys.length === 1) {
                    return null;
                }
            } else if (criteria.fieldInfo.field == 'RequesterId' && keys.length == 0) {
                return null;
            }
            return Object.keys(criteria.value).join('|');
        } else {
            return criteria.value;
        }
    }

    applyFilter() {
        const {criteria, rootReqFields} = this.state;
        const {onApply, setOrdersPredefinedFilters} = this.props;

        let isBackorderedFilter = criteria
            .filter(c => !!c.fieldInfo && (c.fieldInfo.field == SearchFilter.StatusId && c.value['101']));

        let terms = criteria
            .filter(c => !!c.fieldInfo && ((c.name && c.value) || (rootReqFields && rootReqFields.find(r => r.name === c.name))))
            .map(c => ({field: c.fieldInfo.field, value: this.getCriteriaValue(c)}));

        let orderDateItems = _.remove(terms, {field: SearchFilter.OrderDateStart});

        if (orderDateItems && orderDateItems.length > 0) {
            const {start, end} = orderDateItems[0].value;
            if (start) {
                terms.push({field: SearchFilter.OrderDateStart, value: start});
            }
            if (end) {
                terms.push({field: SearchFilter.OrderDateEnd, value: end});
            }
        }
        else
        {
            const tdate = new Date();
            const startDefault = new Date(tdate.getFullYear(), tdate.getMonth() - 18, tdate.getDate());
            terms.push({field: SearchFilter.OrderDateStart, value: startDefault});
        }
        let deliveredFilter = _.remove(terms, {field: SearchFilter.StatusId, value: '100'});
        if (deliveredFilter && deliveredFilter.length > 0) {
            terms.push({field: SearchFilter.IsDelivered, value: true})
        }

        if (isBackorderedFilter && isBackorderedFilter.length > 0) {
            terms.push({field: SearchFilter.IsBackOrdered, value: true})
        }

        _.remove(terms, {field: SearchFilter.RequesterId, value: null});

        onApply(terms);
        setOrdersPredefinedFilters(criteria);
    }

    setupNewFieldData(fieldInfo) {
        const {network: {tokenInfo: {userId}}} = this.props;

        if (fieldInfo.type === 'dateRange') {
            return {
                end: moment().toDate(),
                start: moment().subtract(1, 'month').toDate(),
            };
        } else if (fieldInfo.field === SearchFilter.RequesterId) {
            const currentUser = _.find(fieldInfo.values, {contactId: userId});
            return _.zipObject([currentUser.contactId], [currentUser.contactName]);
        } else if (fieldInfo.type === 'tags') {
            return {};
        } else if (fieldInfo.type === 'select') {
            return fieldInfo.values[0].value;
        }
        return null;
    }

    onFilterTypeChange(event, filter, index) {
        const fieldName = event.target.value;
        const {types, criteria} = this.state;
        const fieldInfo = _.find(types, {name: fieldName});

        let newCriteria = _.clone(criteria);
        let newFilter = _.clone(filter);

        newFilter.name = fieldName;
        newFilter.fieldInfo = fieldInfo;
        newFilter.value = this.setupNewFieldData(fieldInfo);

        newCriteria[index] = newFilter;
        this.setState({criteria: newCriteria});
    }

    addNewFilterRow() {
        const {criteria} = this.state;

        const newField = {
            showSelection: true,
            name: null,
            fieldInfo: null,
            value: null,
        };

        let newCriteria = _.clone(criteria);
        newCriteria.push(newField);
        this.setState({criteria: newCriteria});
    }

    clearParamsFinished() {
        this.applyFilter();
        window.location.reload();
    }

    clearParams() {
        const {params, setResources} = this.props;
        params.wo = '';
        setResources(params);
        const {criteria} = this.state;
        let newCriteria = _.clone(criteria);
        _.remove(newCriteria, {name: 'Status'});
        this.setState({criteria: newCriteria}, this.clearParamsFinished);
    }

    renderTypesDropdown(filter, index) {
        const {types, criteria} = this.state;
        let options = types.filter(t => t.name === filter.name || !_.find(criteria, {name: t.name})).map(f => ({text: f.name, value: f.name}));
        options = _.orderBy(options, ['text'], ['asc']);
        let selectedValue = filter && filter.name == null ? '' : filter && filter.name;

        return (<Dropdown
            id="filterTypes"
            name="filterTypes"
            placeholder="Filter"
            label="Filter"
            options={options}
            onChange={e => this.onFilterTypeChange(e, filter, index)}
            selectedValue={selectedValue}
            required={true}
        />);
    }

    renderView(criteria) {
        if (criteria && criteria.fieldInfo && criteria.fieldInfo.type) {
            switch (criteria.fieldInfo.type) {
                case 'tags':
                    return (<div className="row">
                        <div className="col-md-12">
                            <TextFieldTags
                                placeHolder={`Add a ${criteria.name}`}
                                value={criteria.value}
                                listItems={criteria.fieldInfo.values}
                                keyField={criteria.fieldInfo.keyField || 'text'}
                                valueField={criteria.fieldInfo.valueField || 'value'}
                                onChange={value => this.updateFilterValue(value, criteria)}
                                useMultiSelect={true}
                            />
                        </div>
                    </div>);

                case 'text':
                    return (<div className="row">
                        <div className="col-md-12">
                            <TextField
                                key={criteria.fieldInfo.name}
                                text={criteria.value}
                                onChange={event => this.updateFilterValue(event.target.value, criteria)}
                                label={criteria.name}
                                placeholder={criteria.name} />
                        </div>
                    </div>);

                case 'select':
                    return (<div className="row">
                        <div className="col-md-12">
                            <Dropdown key={criteria.fieldInfo.name}
                                label={criteria.name}
                                onChange={event => this.updateFilterValue(event.target.value, criteria)}
                                options={criteria.fieldInfo.values}
                                selectedValue={criteria.value}
                                required={true} />
                        </div>
                    </div>);

                case 'dateRange':
                    return (<div className="row">
                        <div className="col-md-6">
                            <Datepicker onChange={(date) => this.updateFilterDateValue(date, criteria, 'start')} placeholder="From" value={new Date(criteria.value.start)} />
                        </div>
                        <div className="col-md-6">
                            <Datepicker onChange={(date) => this.updateFilterDateValue(date, criteria, 'end')} placeholder="To" value={new Date(criteria.value.end)} />
                        </div>
                    </div>)
            }
        }
        return null;
    }

    renderFilter(filter, index) {
        const {user: {settings: {showTechNames}}} = this.props;
        const hideRow = filter.name === 'Requester' && !showTechNames;
        if (hideRow) return null;

        return (
            <div className="search-criteria_row row">
                <div className="search-crteria_row_types col-xs-12 col-sm-12 col-md-3">{this.renderTypesDropdown(filter, index)}</div>

                <div className="search-crteria_row_view col-xs-12 col-sm-12 col-md-7">
                    {this.renderView(filter)}
                </div>

                <div className="search-criteria_row--delete">
                    <span style={{cursor: 'pointer'}} onClick={() => this.deleteFilter(filter)}><i className="fa fa-trash" /></span>
                </div>
            </div>
        );
    }

    render() {
        const {criteria, assetid} = this.state;
        const {params: {wo}, network} = this.props;

        return (<div className="search-criteria">
            <div className="container-fluid">
                {(criteria || []).map((item, index) => this.renderFilter(item, index))}
            </div>
            <div className="container-fluid search-criteria_buttons">
                <a onClick={this.addNewFilterRow} className="button button-secondary add-filter">
                    ADD FILTER
                </a>
                <Button onClick={this.applyFilter} secondary={true}>Apply Filters</Button> 
            </div>
            {wo && assetid && network.initialPage === '/orders/history' &&
                <div className=" container-fluid" role="alert">
                    <div className="search-criteria_worow row alert-warning">
                        <div className="col-md-8">
                            Work Order Id: {wo}<br />
                            Asset: {assetid}<br /><br />
                        </div>
                        <div className="col-md-4">
                            <Button className="float-right" onClick={this.clearParams} secondary={true}>Exit Export Orders</Button> 
                        </div>
                    </div>
                </div>}
        </div>)
    }
}
