import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import {MultiGrid} from 'components/MultiGrid';
import PageMetaData from 'components/PageMetaData';
import SearchCriteria from 'components/SearchCriteria';
import * as NetworkStore from 'stores/Network';
import * as SettingsStore from 'stores/Settings';
import * as ParamsStore from 'stores/Params';
import _ from 'lodash';

import 'less/orders.less';

@withRouter
@connect((state) => ({network: state.network, params: state.params, settings: state.settings}), _.merge({}, SettingsStore.actionCreators))
export default class OrderSearch extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        network: NetworkStore.StateShape,
        settings: SettingsStore.StateShape,
        params: ParamsStore.StateShape,
    };

    constructor(props) {
        super(props);
        this.performSearch = this.performSearch.bind(this);
        this.state = {
            gridName: 'ordersColumns',
            customEmptyMessage: null,
            isBusy: true,
            columns: [],
            view: 'Grid',
            orders: [],
            ordersPagination: props.settings.allOrdersPagination,
        };
        this.gotoOrderDetail = this.gotoOrderDetail.bind(this);
        this.handleEmitPageSize = this.handleEmitPageSize.bind(this)
    }

    componentDidMount() {
        const {gridName} = this.state;

        axios.get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`).then(x => {
            this.setState({columns: x.data.columns, view: x.data.view});
        });
    }

    handleEmitPageSize(newPagination) {
        const {setAllOrdersPagination} = this.props
        setAllOrdersPagination(newPagination);
        this.setState({ordersPagination: newPagination})
    }
    gotoOrderDetail(order) {
        const {history} = this.props;
        const {location} = window;
        
        if (order.isServiceItem) {
            history.push(`/service/${order.lineItemId}`, {from: location.pathname + location.search});
        } else {
            let quotesArray = [1, 2, 3, 4, 9, 10, 14];

            if ((quotesArray.indexOf(order.statusId) !== -1) || (order.statusId === 26 && !order.extStatusId))
                history.push(`/quotes/` + order.lineItemId);
            else
                history.push(`/order/` + order.lineItemId);
        }
    }

    performSearch(args) {
        const {params, network: {initialPage, tokenInfo: {subscriptionId}}} = this.props;
        this.setState({isBusy: true});

        const request = {
            start: 0,
            limit: 10000,
            terms: args,
        };

        const requesterTerm = request.terms.find(r => r.field == 'RequesterId');
        const requesterTermIndex = request.terms.indexOf(requesterTerm);

        if (requesterTerm && requesterTerm.value === 'All') {
            if (request.terms.length == 1) {
                this.setState({customEmptyMessage: 'To see results for All Requesters, add another Filter'});
            } else {
                this.setState({customEmptyMessage: null});
            }

            request.terms.splice(requesterTermIndex, 1);
        } else {
            this.setState({customEmptyMessage: null});
        }

        axios.post('/ShoppingService/api/v1/lineitems/search', request).then(response => {
            const {data: {records, totalRecords}} = response;
            let data = records;
            // TMS order filtering
            if (params.wo_id && params.wo && subscriptionId && initialPage === '/orders/history') {
                data = _.filter(data, function (o) {
                    if (!o.customerOrderKey) return true;
                    return false;
                });
                
            }
            
            data.forEach(x => {
                x.unitPrice = x.unitPrice.toFixed(2);
                x.extendedPrice = x.extendedPrice.toFixed(2);
            });
            this.setState({
                orders: data,
                totalRecords: totalRecords, 
                isBusy: false}
            );
        }).catch(() => this.setState({isBusy: false}));
    }

    render() {
        const {gridName, customEmptyMessage, columns, orders, view, isBusy} = this.state;

        return (<div>
            <PageMetaData 
                title="Order Search" 
                pageType="other" 
                trackAnalytics={true} />
            <MultiGrid pageSize={this.state.ordersPagination} emitPageSizeChanged={::this.handleEmitPageSize} gridName={gridName} view={view} data={orders} columns={columns} 
                loadingData={isBusy} showShipInfo={true} disableCardView={true}
                label="My Account" subLabel="All Orders" customEmptyMessage={customEmptyMessage} onSelect={::this.gotoOrderDetail}>
                <SearchCriteria onApply={this.performSearch} />
            </MultiGrid>
        </div>);
    }
}
